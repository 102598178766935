import '../styles/Footer.css';

const Footer = () => {
    return (
        <div id="footer" className='d-flex justify-content-center align-items-center'>
            <h6>Flow State Žirgynas</h6>
        </div>
    )
}

export default Footer;